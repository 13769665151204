import React, { useState, useEffect, useContext, createContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import {
  Layout as LayoutComponent,
  Loader
} from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
import GlobalContext from '../../state/GlobalContext';
import { BasketModalWizard } from '../BasketModalWizard';
import { GridItemProps, OrderCustomization, Breadcrumb } from '../../types';
import { ProductDetailModal, InactivityModal, SupervisorModal } from '..';

export const LayoutContext = createContext<{
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: Function;
  showProductDetailModal: boolean;
  productDetailModalInfo?: {
    product: GridItemProps & { customization?: OrderCustomization };
    isNew: boolean;
  };
  setShowProductDetailModal: Function;
  setProductDetailModalInfo: Function;
  showBasketModal: boolean;
  setShowBasketModal: Function;
}>({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  showProductDetailModal: false,
  setShowProductDetailModal: () => {},
  setProductDetailModalInfo: () => {},
  showBasketModal: false,
  setShowBasketModal: () => {}
});

const TopMargin = styled.div`
  margin-top: 20%;
`;

export default ({ children }: { children: any; location: Location }) => {
  const [showBasketModal, setShowBasketModal] = useState<boolean>(false);
  const [showProductDetailModal, setShowProductDetailModal] = useState<boolean>(
    false
  );
  const [showSupervisorModal, setShowSupervisorModal] = useState<boolean>(
    false
  );
  const [productDetailModalInfo, setProductDetailModalInfo] = useState<{
    product: GridItemProps;
    isNew: boolean;
  }>();
  const [disableInactivity, setDisableInactivity] = useState(false);
  const [showInactivityModal, setShowInactivityModal] = useState<boolean>(
    false
  );
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const {
    basket,
    imageUrl,
    idleResetMilliseconds,
    loading,
    visualProfileColors,
    rootComposerGroup
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const styles = {
    basketButton: {
      background: visualProfileColors?.button?.primary?.background
    }
  };

  const goToRootGroup = () => {
    navigate(`/groups/${rootComposerGroup?.groupUrlName}`);
  };

  // if user tries to access /groups, then we route him to root group

  useEffect(() => {
    // When the modal is shown, we want a fixed body so that the application does not scroll in the background
    document.body.style.overflow =
      showBasketModal || showProductDetailModal || showSupervisorModal
        ? 'hidden'
        : 'initial';
  }, [showBasketModal, showProductDetailModal, showSupervisorModal]);

  const breadcrumbsClicked = (breadcrumb: Breadcrumb) => {
    const breadcrumbIndex = breadcrumbs.indexOf(breadcrumb);
    const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbIndex + 1);
    const groupPath = newBreadcrumbs.map(b => b.value.groupUrlName).join('/');
    setBreadcrumbs(newBreadcrumbs);
    navigate(`/groups/${groupPath}`);
  };

  const goBackOneBreadcrumb = () => {
    if (breadcrumbs.length > 1) {
      const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);
      const groupPath = newBreadcrumbs.map(b => b.value.groupUrlName).join('/');
      setBreadcrumbs(newBreadcrumbs);
      navigate(`/groups/${groupPath}`);
    } else {
      navigate('/');
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <TopMargin>
          <Loader width={200} />
        </TopMargin>
      );
    }
    return <>{children}</>;
  };

  return (
    <>
      <LayoutContext.Provider
        value={{
          breadcrumbs,
          setBreadcrumbs,
          showProductDetailModal,
          setShowProductDetailModal,
          productDetailModalInfo,
          setProductDetailModalInfo,
          showBasketModal,
          setShowBasketModal
        }}
      >
        <IdleTimer
          key="idleTimer"
          startOnMount
          onActive={() => setShowInactivityModal(false)}
          onIdle={() => setShowInactivityModal(!disableInactivity)}
          timeout={idleResetMilliseconds}
        >
          <LayoutComponent
            styles={styles}
            searchPlaceholder={t('Search')}
            logoUrl={imageUrl}
            breadcrumbs={breadcrumbs}
            onBreadcrumbClicked={breadcrumbsClicked}
            onLogoClicked={goToRootGroup}
            onSettingsClicked={() => setShowSupervisorModal(true)}
            onBasketClicked={() => {
              setShowBasketModal(true);
            }}
            basketQuantity={basket.totalQuantity}
            onBackButtonClicked={
              breadcrumbs.length > 1 ? goBackOneBreadcrumb : undefined
            }
          >
            {renderContent()}
          </LayoutComponent>
          <BasketModalWizard
            showModal={showBasketModal}
            setDisableInactivity={(disable: boolean) =>
              setDisableInactivity(disable)
            }
            handleShowModal={(showModalValue: boolean) =>
              setShowBasketModal(showModalValue)
            }
          />
          <SupervisorModal
            showModal={showSupervisorModal}
            onModalClosed={() => setShowSupervisorModal(false)}
          />
          <InactivityModal showModal={showInactivityModal} />
          <ProductDetailModal />
        </IdleTimer>
      </LayoutContext.Provider>
    </>
  );
};
