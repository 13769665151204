import { AxiosResponse } from 'axios';
import api from '../utils/api';
import { Config } from '../types';

export const getConfig = async (): Promise<AxiosResponse<Config>> => {
  try {
    return await api.get('/Config');
  } catch (e) {
    return e.response;
  }
};
