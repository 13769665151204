import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  TransactionStatus,
  BasketModalContent
} from '@k3imagine/self-serve-components';
import { PaymentStatus as PaymentStatusType } from '../../../../types/index';
import GlobalContext from '../../../../state/GlobalContext';

type PaymentStatusProps = {
  status?: PaymentStatusType;
  orderId?: string | number;
  averageWaitingTimeInSeconds?: number;
  onGoToBasketOverview: Function;
  onGoToStart: Function;
};

const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
`;

const CounterText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  font-weight: 300;
`;

const PaymentStatus = ({
  status,
  onGoToBasketOverview,
  orderId,
  averageWaitingTimeInSeconds,
  onGoToStart
}: PaymentStatusProps) => {
  const { t } = useTranslation();
  const { hideOrderWaitingTime, visualProfileColors } = useContext(
    GlobalContext
  );
  const [counter, setCounter] = useState<number>(5);

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter <= 1) {
        if (status === PaymentStatusType.Success) {
          onGoToStart();
        } else {
          onGoToBasketOverview();
        }
      } else {
        setCounter(counter - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);

  const renderContent = () => {
    if (status === PaymentStatusType.Success) {
      return (
        <Wrapper>
          <TransactionStatus
            status="Success"
            title={t('Basket.Success')}
            description={t('Basket.PaymentApproved')}
            averageWaitTimeSeconds={
              hideOrderWaitingTime ? undefined : averageWaitingTimeInSeconds
            }
            orderNumber={orderId}
            orderNumberLabel={`${t('Order')}:`}
            averageWaitingTimeLabel={`${t('EstimatedWaitingTime')}:`}
            buttonText={t('GoBack')}
            onButtonClicked={() => onGoToStart()}
          />
          <CounterText>
            {t('WeWillTakeYouBackIn', { seconds: counter })}
          </CounterText>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <TransactionStatus
          status="Failed"
          title={t('Basket.TransactionFailed')}
          buttonText={t('Basket.BackToOverview')}
          onButtonClicked={() => onGoToBasketOverview()}
        />
        <CounterText>
          {t('WeWillTakeYouBackToOverviewIn', { seconds: counter })}
        </CounterText>
      </Wrapper>
    );
  };

  return (
    <BasketModalContent title={t('Basket.Cart')} styles={styles}>
      {renderContent()}
    </BasketModalContent>
  );
};

export default PaymentStatus;
