import { TileType } from './index';

export type ComposerType = {
  rootComposerGroupId: number;
  rootComposerGroup: RootComposerGroup;
  tiles: ComposerGroupItem[];
};

export type RootComposerGroup = {
  id: number;
  displayName: string;
  groupUrlName?: string;
  imageUrl: string;
  name: string;
};

export enum ImagePosition {
  Fill = 'Fill',
  Centered = 'Centered'
}

export type ComposerGroupItem = {
  id: number;
  referenceId: number;
  displayName: string;
  imageUrl: string;
  type: TileType;
  priceRange?: PriceRange;
  composerGroupId: number;
  taxPercent?: number;
  tax?: number;
  hasInfo?: boolean;
  redirectUrl?: string;
  groupUrlName?: string;
  imagePosition?: ImagePosition;
  isCustomizable?: boolean;
};

export type PriceRange = {
  from: number;
  to?: number;
};
