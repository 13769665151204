import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const TextWrapper = styled.p<{ color: string }>`
  font-size: 3vw;
  color: ${({ color }) => color};
`;

type WrapperProps = {
  showFlags: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  animation: ${fadeIn} 1s;
  display: grid;
  grid-template-columns: ${props => (props.showFlags ? '100%' : '80% 20%')};
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LeftWrapper = styled.div`
  display: grid;
  grid-template-columns: 32% 68%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FlagWrapper = styled.div`
  text-align: center;
`;

export const ScreenWrapper = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 80vh;
  display: grid;
  z-index: 100;
`;

export const ScreenFooter = styled.div<{ color?: string }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 20vh;
  background-color: ${({ color }) => color || 'black'};
  z-index: 100;
`;
