import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Only supporting English, Greek and Bulgarian now, this list will be dynamic in the future.
export const supportedLanguages = ['gb', 'gr', 'bg'];

export const isSupportedLanguage = (language: string) =>
  supportedLanguages.includes(language);

/* eslint-disable global-require */
const resources = {
  gb: require('./en-gb/translations.json'),
  gr: require('./el-gr/translations.json'),
  bg: require('./bg-bg/translations.json')
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    resources,
    fallbackLng: 'gb',

    // have a common namespace used around the full app
    ns: ['Translations'],
    defaultNS: 'Translations',

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
