import React, { useEffect, useState, ComponentProps, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Modal, BasketModal, notify } from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { Extensions } from '@k3imagine/imagine-extension-framework/dist/';
import GlobalContext from '../../state/GlobalContext';
import { WizardSteps } from './WizardSteps';
import {
  BasketOverview,
  Payment,
  PaymentStatus as PaymentStatusComponent,
  BasketValidation
} from './index';
import { PaymentStatus, SalesResponse } from '../../types';
import {
  getNewAccessToken,
  getPosAccessToken
} from '../../services/authentication.service';

type ModalWizardProps = {
  showModal: boolean;
  handleShowModal: Function;
  setDisableInactivity: (disable: boolean) => void;
};

type BasketModalProps = ComponentProps<typeof BasketModal>;

export const ContentWrapper = styled.div`
  height: 100%;
`;

const BasketModalWizard = ({
  showModal,
  handleShowModal,
  setDisableInactivity
}: ModalWizardProps) => {
  const [currentStep, setCurrentStep] = useState<WizardSteps>(
    WizardSteps.BasketOverview
  );
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();
  const [saleResponse, setSaleResponse] = useState<SalesResponse>();
  const { t } = useTranslation();
  const extensionIframeRef = React.useRef<HTMLIFrameElement>(null);

  const {
    clearBasket,
    getComposerTiles,
    chosenArmDevice,
    basket,
    shopId
  } = useContext(GlobalContext);

  const imagineExtensionFramework: Extensions = new Extensions(
    Number(shopId),
    basket.externalUid,
    `${process.env.API_URL}/api/${process.env.API_VERSION}`,
    getPosAccessToken(),
    async () => {
      const newToken = await getNewAccessToken();
      if (!newToken) {
        return '';
      }
      return newToken.access_token;
    }
  );

  useEffect(() => {
    if (currentStep === WizardSteps.Payment) {
      setDisableInactivity(true);
    } else {
      setDisableInactivity(false);
    }
  }, [currentStep]);

  useEffect(() => {
    if (showModal && !chosenArmDevice) {
      notify({
        message: `${t('Error.NoPaymentDeviceSelectedToaster')}`,
        type: 'error'
      });
    }
  }, [showModal]);

  let content;
  let modalConfig: BasketModalProps = {
    title: t('Basket.Cart'),
    isHidden: !showModal
  };

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const triggerExtensionEvent = async (event: string) => {
    const extensionSubscriptions = await imagineExtensionFramework.triggerEvent(
      event
    );
    if (extensionSubscriptions && extensionSubscriptions.length > 0) {
      const returnToStep = currentStep;
      setCurrentStep(WizardSteps.Extension);
      if (extensionIframeRef.current) {
        const extensionIframe: HTMLIFrameElement = extensionIframeRef.current;
        await imagineExtensionFramework.invokeExtensions(
          extensionSubscriptions,
          extensionIframe
        );
        ReactDOM.unmountComponentAtNode(extensionIframe);
      }
      setCurrentStep(returnToStep);
    }
  };

  const goToPrevStep = () => {
    if (currentStep === WizardSteps.Payment) {
      // skip Basket Validation step if going back from Payment step
      setCurrentStep(currentStep - 2);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const closeModal = () => {
    handleShowModal(false);
    setCurrentStep(WizardSteps.BasketOverview);
  };

  const goToStart = () => {
    navigate('/');
    closeModal();
  };

  const Wrapper = styled.div`
    text-align: center;
    color: black;
    font-size: 1rem;
    height: 100%;
  `;

  const ExtensionIframe = styled.iframe`
    width: 100%;
    height: 100%;
  `;

  switch (currentStep) {
    case WizardSteps.BasketOverview:
      modalConfig = {
        ...modalConfig,
        onClose: () => closeModal()
      };
      content = (
        <BasketOverview
          goNext={async () => {
            await triggerExtensionEvent('OnBeginCheckout');
            goToNextStep();
          }}
          closeModal={closeModal}
          clearBasket={() => {
            clearBasket();
            closeModal();
          }}
        />
      );
      break;
    case WizardSteps.BasketValidation:
      modalConfig = {
        ...modalConfig,
        onClose: () => closeModal()
      };
      content = (
        <BasketValidation
          goNext={goToNextStep}
          goBack={goToPrevStep}
          goToStart={() => {
            getComposerTiles();
            goToStart();
          }}
          goToPaymentStatus={(paymentStatusResponse: PaymentStatus) => {
            setPaymentStatus(paymentStatusResponse);
            setCurrentStep(WizardSteps.PaymentStatus);
          }}
        />
      );
      break;
    case WizardSteps.Payment:
      content = (
        <Payment
          goNext={({
            paymentStatusData,
            saleResponseData
          }: {
            paymentStatusData: PaymentStatus;
            saleResponseData: SalesResponse;
          }) => {
            setPaymentStatus(paymentStatusData);
            setSaleResponse(saleResponseData);
            goToNextStep();
          }}
        />
      );
      break;
    case WizardSteps.PaymentStatus:
      modalConfig = {
        ...modalConfig,
        onClose: () => closeModal()
      };
      content = (
        <PaymentStatusComponent
          orderId={saleResponse?.orderId}
          averageWaitingTimeInSeconds={saleResponse?.averageWaitTimeSeconds}
          status={paymentStatus}
          onGoToBasketOverview={() =>
            setCurrentStep(WizardSteps.BasketOverview)
          }
          onGoToStart={goToStart}
        />
      );
      break;

    //  Extension Framework - START
    case WizardSteps.Extension:
      modalConfig = {
        ...modalConfig,
        onClose: () => {
          imagineExtensionFramework.disposeMessageListeners();
          closeModal();
        }
      };
      content = (
        <Wrapper>
          <ExtensionIframe ref={extensionIframeRef} />
        </Wrapper>
      );
      break;
    //  Extension Framework - END

    default:
      return null;
  }

  return (
    <Modal {...modalConfig}>
      <ContentWrapper>{content}</ContentWrapper>
    </Modal>
  );
};

export default BasketModalWizard;
