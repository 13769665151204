import styled from 'styled-components';

export const LoginContent = styled.div`
  text-align: center;
  color: black;
  height: 100%;

  overflow: auto;
  display: grid;
  grid-template-rows: 15% 15% 1fr 15%;
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: 200;
  margin: auto;
`;

export const PasswordBox = styled.div`
  margin: auto;
  background: #fff;
  border-radius: 3px;
  width: 250px;
  height: 40px;
  line-height: 40px;

  font-size: 21px;
  letter-spacing: 0.1em;
`;

export const NumpadWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  width: 80%;
  margin: auto;
`;

export const FunctionsContent = styled.div`
  text-align: center;
  color: black;
  height: 100%;

  overflow: auto;
  display: grid;
  grid-template-rows: 15% 1fr 15%;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  margin-top: 60px;
  margin-bottom: 20px;
`;

export const FullScreenButtonWrapper = styled.div`
  display: inline-block;
  width: 100px;
  margin: 5px;
`;

export const PickList = styled.select`
  width: 300px;
  height: 50px;
  border: none;
  font-size: 16px;
`;

export const PickListOption = styled.option`
  height: 40px;
  font-size: 16px;
`;

export const ComponentWrapper = styled.div`
  width: 300px;
  margin: 30px auto;
`;
