import jobs from './jobs';
import { IMigration } from './migration.type';
import {
  getNamedLocalStorage,
  setNamedLocalStorage
} from '../utils/namedLocalStorage';

const LAST_MIGRATION_ID = 'lastMigrationId';

export const runMigrations = () => {
  const prevId = Number(getNamedLocalStorage(LAST_MIGRATION_ID)) || 0;
  let nextId = prevId;

  jobs.forEach((job: IMigration) => {
    if (job.id > prevId) {
      job.execute();
      nextId = job.id;
    }
  });

  setNamedLocalStorage(LAST_MIGRATION_ID, String(nextId));
};
