import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as AuthService from '../services/authentication.service';

type RequestConfig = AxiosRequestConfig & {
  retry?: boolean;
};

// Here we can add everything we want to be used across all requests
const api = axios.create({
  baseURL: `${process.env.API_URL}/api/${process.env.API_VERSION}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(async options => {
  const token = AuthService.getPosAccessToken();
  const newOptions = options;
  newOptions.headers.Authorization = `Bearer ${token}`;
  newOptions.headers['Shop-Context'] = AuthService.getContextShopId();
  newOptions.headers['Request-Id'] = uuidv4();

  return newOptions;
});

// If a request fails, we will fetch a new token and try the request again
api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest: RequestConfig = error.config;
    const { response } = error;

    if (response && response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;

      const newToken = await AuthService.getNewAccessToken();
      if (newToken) {
        originalRequest.headers.Authorization = `Bearer ${newToken.access_token}`;
      }

      return axios(originalRequest);
    }

    if (response && response.status === 401 && originalRequest.retry) {
      AuthService.logout();
    }

    return Promise.reject(error);
  }
);

export default api;
