import styled, { css } from 'styled-components';

export const ModalBackground = styled.div<{ showModal: boolean }>`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 150;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  display: flex;

  ${({ showModal }) =>
    !showModal &&
    css`
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.2s, opacity 0.2s linear;
    `}

  ${({ showModal }) =>
    showModal &&
    css`
      visibility: visible;
      opacity: ;
      transition: visibility 0.2s 0s, opacity 0.2s linear;
    `}
`;

export const Modal = styled.div`
  height: 80%;
  width: 90%;
  max-height: 300px;
  max-width: 500px;

  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: white;
`;

export const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 50px 0;
`;

export const ModalDescription = styled.div`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 30px 0;
`;

export const Timer = styled.div`
  font-size: 32px;
  font-weight: 500;
`;

export const ContinueButton = styled.button`
  background-color: '#f9f9f9';
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border: 0;
  width: 200px;
  height: 50px;
  margin-top: 10px;
  font-size: 18px;
  background-color: '#f9f9f9';
`;
