export enum CookieTypes {
  LanguageCode = 'languageCode',
  ContextShopId = 'contextShopId',
  TenantId = 'tenantId',
  PortalApiToken = 'portalApiToken',
  PortalRefreshToken = 'portalRefreshToken',
  PosRefreshToken = 'posRefreshToken',
  PosAccessToken = 'posAccessToken',
  LogRocketEnabled= 'logRocketEnabled'
}
