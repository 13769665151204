import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Numpad,
  Button,
  PaymentButton,
  Select,
  notify
} from '@k3imagine/self-serve-components';
import { navigate } from 'gatsby';
import GlobalContext from '../../state/GlobalContext';
import * as S from './SupervisorModal.styles';
import { SupervisorModalType } from './SupervisorModal.types';
import {
  getNamedLocalStorage,
  setNamedLocalStorage
} from '../../utils/namedLocalStorage';
import {
  PaymentProviderEnum,
  PaymentServiceDataType
} from '../../services/payment/payment.type';
import { ArmDevice } from '../../types';
import { getPaymentService } from '../../services/payment/payment.service';

const SupervisorModal = ({
  showModal,
  onModalClosed = () => {}
}: SupervisorModalType) => {
  const checkIfFullscreen = (): boolean => {
    return !!document.fullscreenElement;
  };

  const [supervisorLoggedIn, setSupervisorLoggedIn] = useState<boolean>(false);
  const [enteredClerkPrefix, setEnteredClerkPrefix] = useState<string>();
  const [fullScreen, setFullScreen] = useState<boolean>(checkIfFullscreen());
  const {
    clerkNumberPrefix,
    availableArmDevices,
    setChosenArmDevice,
    clearBasket,
    refreshKiosk,
    visualProfileColors,
    getLastSale,
    chosenArmDevice,
    currencyCode
  } = useContext(GlobalContext);

  const { t } = useTranslation();

  const prefixToPattern = () => {
    if (!enteredClerkPrefix) {
      return '';
    }
    const clerkPrefixArray = Array.from(enteredClerkPrefix, String);
    return clerkPrefixArray.map(() => 'x');
  };

  const matchedClerkPrefix = () => {
    if (enteredClerkPrefix) {
      return enteredClerkPrefix === clerkNumberPrefix;
    }
    return false;
  };

  const closeModal = () => {
    setSupervisorLoggedIn(false);
    setEnteredClerkPrefix(undefined);
    onModalClosed();
  };

  const armDeviceChanged = (value: ArmDevice) => {
    if (value) {
      const device = value?.type
        ? value
        : { ...value, type: PaymentProviderEnum.Arm };

      if (!device?.url) {
        notify({
          message: t('Supervisor.PaymentDeviceNotReadyError'),
          type: 'error'
        });
        return;
      }

      setNamedLocalStorage('armDevice', JSON.stringify(device));
      setChosenArmDevice(device);
    } else {
      setNamedLocalStorage('armDevice', JSON.stringify(undefined));
      setChosenArmDevice(undefined);
    }
  };

  const requestFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }
    setFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setFullScreen(false);
  };

  const onRefreshKiosk = () => {
    clearBasket(true);
    refreshKiosk();
    navigate('/');
  };

  const onReprintReceipt = async () => {
    const saleResponse = getLastSale();
    const data: PaymentServiceDataType = {
      type: chosenArmDevice.type,
      totalPrice: 0,
      currency: currencyCode,
      armDevice: chosenArmDevice
    };
    const { reprintCompleteReceipt } = await getPaymentService(data);
    reprintCompleteReceipt(saleResponse);
  };

  const lastSaleAvailable = () => getLastSale() != null;

  const renderSupervisorLogin = () => {
    return (
      <S.LoginContent>
        <S.Title>{t('Supervisor.EnterSupervisorCode')}</S.Title>
        <S.PasswordBox>{prefixToPattern()}</S.PasswordBox>
        <S.NumpadWrapper>
          <Numpad
            onValueChanged={({ value }: { value: string }) =>
              setEnteredClerkPrefix(value)
            }
          />
        </S.NumpadWrapper>
        <S.ButtonWrapper>
          <Button
            label={t('Continue')}
            disabled={!matchedClerkPrefix()}
            onClicked={() => setSupervisorLoggedIn(true)}
            styles={visualProfileColors?.button?.primary}
          />
        </S.ButtonWrapper>
      </S.LoginContent>
    );
  };

  const renderSupervisorFunctions = () => {
    const normalizedArmDeviceOptions = availableArmDevices.map(a => ({
      label: a.name,
      value: a.id,
      ...a
    }));

    return (
      <S.FunctionsContent>
        <S.Title>{t('Supervisor.SupervisorFunctions')}</S.Title>
        <div>
          <S.SubTitle>{t('Supervisor.FullScreen')}:</S.SubTitle>
          <S.FullScreenButtonWrapper>
            <PaymentButton
              label="On"
              onClicked={() => requestFullScreen()}
              theme={fullScreen ? 'Dark' : 'Light'}
            />
          </S.FullScreenButtonWrapper>
          <S.FullScreenButtonWrapper>
            <PaymentButton
              label="Off"
              onClicked={() => exitFullScreen()}
              theme={fullScreen ? 'Light' : 'Dark'}
            />
          </S.FullScreenButtonWrapper>

          <S.SubTitle>{t('Supervisor.ChooseArmDevice')}:</S.SubTitle>
          <S.ComponentWrapper>
            <Select
              value={JSON.parse(getNamedLocalStorage('armDevice') || '{}')}
              options={normalizedArmDeviceOptions}
              onChange={option => armDeviceChanged(option || {})}
              searchable
            />
          </S.ComponentWrapper>
          <S.ComponentWrapper>
            <PaymentButton
              label={t('RefreshKiosk')}
              onClicked={() => onRefreshKiosk()}
            />
          </S.ComponentWrapper>
          <S.ComponentWrapper>
            <PaymentButton
              disabled={!lastSaleAvailable()}
              label={t('ReprintReceipt')}
              onClicked={() => onReprintReceipt()}
            />
          </S.ComponentWrapper>
        </div>
        <S.ButtonWrapper>
          <Button
            label={t('Close')}
            onClicked={closeModal}
            styles={visualProfileColors?.button?.primary}
          />
        </S.ButtonWrapper>
      </S.FunctionsContent>
    );
  };

  return (
    <Modal isHidden={!showModal} onClose={closeModal}>
      {supervisorLoggedIn
        ? renderSupervisorFunctions()
        : renderSupervisorLogin()}
    </Modal>
  );
};

export default SupervisorModal;
