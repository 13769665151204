import uuidv4 from 'uuid/v4';
import * as ArmTypes from './arm-payment.type';
import {
  PaymentServiceBasketItem,
  PaymentServiceDataType
} from '../../payment.type';

export const printTranslations = {
  giftCertificate: 'Gift certifcate',
  creditNote: 'Credit note',
  setStart: 'Set start',
  setLine: 'Set item',
  setEnd: 'Set end',
  discount: 'Discount',
  totalDiscount: 'Total discount',
  total: 'Total',
  itemAmount: 'Number of items',
  vatIncluded: 'VAT',
  vatIncludedTotal: 'Total VAT',
  price: 'Pris',
  youWereServedBy: 'Served by',
  currency: 'EUR',
  zero: 'Zero',
  one: 'One',
  two: 'Two',
  three: 'Three',
  four: 'Four',
  five: 'Five',
  six: 'Six',
  seven: 'Seven',
  eight: 'Eight',
  nine: 'Nine',
  return: 'Return',
  reason: 'Reason',
  returnReceipt: 'RECEIPT FOR RETURN OF ITEMS',
  customerName: 'Name',
  customerAddress: 'Address',
  customerCity: 'City',
  customerPhone: 'Phone',
  customerSignature: 'Signature',
  customerNumber: 'Customer',
  openDrawer: 'Opening till',
  vatNumber: 'VAT number',
  totalNumberItems: 'Number of items',
  expense: 'Expense',
  copy: 'Copy',
  terminalJournal: 'Terminal journal',
  receiptJournal: 'Receipt journal',
  periodStart: 'Period start',
  periodEnd: 'Period end',
  pickList: 'Pick list',
  orderLineVariant: 'Variant',
  orderLineNotes: 'Notes',
  paymentTypes: {
    Cash: 'Cash',
    Card: 'Card',
    ManualCard: 'Manual card',
    ForeignCurrency: 'Currency',
    GiftCertificate: 'Gift certifcate',
    ForeignGiftCertificate: 'Foreign gift certificate',
    CreditVoucher: 'Credit note',
    ForeignCreditVoucher: 'Foreign credit note',
    Webshop: 'Webshop',
    Account: 'Account',
    Bank: 'Bank',
    DebitCard: 'Debit card',
    CreditCard: 'Credit card',
    MobilePayment: 'Mobile payment',
    Change: 'Change',
    Rounding: 'Rounding',
    Currency: 'Currency',
    LoyaltyBonus: 'Loyalty bonus',
    LoyaltyDiscount: 'Loyalty Discount'
  }
};

export const composePaymentData = ({
  basketItems,
  totalPrice,
  currency,
  armDevice
}: PaymentServiceDataType): ArmTypes.PaymentRequest => {
  const itemList: ArmTypes.ArmDevicePaymentData[] = [];
  basketItems.forEach(
    ({ id, price, label, vat, itemNo, quantity }: PaymentServiceBasketItem) => {
      itemList.push({
        group: 0, //
        styleId: 0,
        variantId: id,
        supplier: 1,
        itemNo: id.toString(),
        itemGroup: 1,
        itemGroupName: 'Kiosk ItemGroup',
        vat,
        parameter1: itemNo, // <- item no
        parameter2: label, // <- item description
        parameter3: id.toString(),
        parameter4: label,
        parameter5: '',
        parameter6: '',
        parameter7: '', // <- meal or item?
        parameter8: null,
        parameter9: null,
        parameter10: null,
        parameter11: null,
        parameter12: null,
        parameter13: '',
        salesPrice: price.toString(), // <- item price
        costPrice: price.toString(), // <- item price
        taxCodeId: 0,
        quantity, // <- item quantity
        returnCode: 0,
        discountCode: 0,
        calculatedDiscount: '0',
        effectiveSalesPrice: price.toString(), // <- item price
        transactionType: 'Sale',
        templateType: 'sale',
        enteredText: '',
        insuranceNumber: '',
        uuid: uuidv4(),
        clerkId: 1,
        displayInKitchen: true // TODO: add optional changes
      });
    }
  );

  return {
    receipt: {
      receiptId: -1,
      dataType: 'Sale',
      shop: '-1',
      clerk: 1,
      clerkName: 'Kiosk',
      posId: -1,
      date: new Date().toISOString(), // "2018-12-04T13:11:13+00:00", // <-
      items: itemList,
      selectedItemLineIndex: -1,
      transactionType: 'Sale',
      armId: armDevice.id,
      customerNumber: 0,
      payments: []
    },
    group: '1',
    paymentType: 'Card',
    amount: totalPrice.toString(),
    rounding: 0,
    foreignCurrencyCode: null,
    currencyName: currency, // <- currency
    exchangerate: 100,
    printTranslations
  };
};
