import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { BundleGroupSelection as BundleGroupSelectionComponent } from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';
import {
  BundleSelection as BundleSelectionType,
  BundleGroup
} from '../../../types';
import GlobalContext from '../../../state/GlobalContext';

export const Title = styled.div`
  margin-left: 10px;
  font-weight: 300;
  font-size: 16px;
  display: inline-block;
  font-style: italic;
`;

const BundleSelection = ({
  bundleGroups,
  selectedBundleGroups = [],
  onBundleSelectionChanged = () => {}
}: {
  bundleGroups?: BundleGroup[];
  selectedBundleGroups?: BundleSelectionType[];
  onBundleSelectionChanged?: Function;
}) => {
  const { defaultLocale } = useContext(GlobalContext);
  const [bundleSelectionsState, setBundleSelectionsState] = useState<
    BundleSelectionType[]
  >(selectedBundleGroups || []);

  let mappedBundleGroups;

  if (bundleGroups && bundleGroups.length > 0) {
    mappedBundleGroups = bundleGroups.map((s: BundleGroup) => {
      let selectedOption;
      if (selectedBundleGroups) {
        selectedBundleGroups.forEach(g => {
          if (g.bundleGroupId === s.id) {
            const bundleGroupItem = s.bundleGroupItems.find(
              i => i.id === g.referenceId
            );

            selectedOption = {
              id: g.referenceId,
              label: g.label,
              imageUrl: bundleGroupItem?.imageUrl
            };
          }
        });
      }

      return {
        id: s.id,
        label: s.displayName,
        selectedOption,
        options: s.bundleGroupItems.map(i => ({
          id: i.id,
          label: i.displayName,
          imageUrl: i.imageUrl
        }))
      };
    });
  }

  const handleOptionChanged = ({
    Group,
    SelectedOption
  }: {
    Group: { id: number };
    SelectedOption: { id: number; label: string; imageUrl: string };
  }) => {
    let newBundleSelections;
    if (SelectedOption) {
      newBundleSelections = [
        ...bundleSelectionsState,
        {
          referenceId: SelectedOption.id,
          label: SelectedOption.label,
          imageUrl: SelectedOption.imageUrl,
          bundleGroupId: Group.id
        }
      ];
    } else {
      newBundleSelections = bundleSelectionsState.filter(
        g => g.bundleGroupId !== Group.id
      );
    }
    setBundleSelectionsState(newBundleSelections);
    onBundleSelectionChanged(newBundleSelections);
  };

  return (
    <>
      <Title>{useTranslation().t('ModifierSelection.ChooseOneInEach')}</Title>
      <BundleGroupSelectionComponent
        locale={defaultLocale}
        bundleGroups={mappedBundleGroups}
        onChanged={handleOptionChanged}
      />
    </>
  );
};

export default BundleSelection;
