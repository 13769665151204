import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Loader, BasketModalContent } from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';

import { getPaymentService } from '../../../../services/payment/payment.service';
import { PaymentServiceDataType } from '../../../../services/payment/payment.type';
import { putSales } from '../../../../services/sales.service';
import { PaymentStatus } from '../../../../types';
import GlobalContext from '../../../../state/GlobalContext';

const Wrapper = styled.div`
  text-align: center;
  color: black;
  font-size: 1rem;
`;

const Title = styled.div`
  margin-bottom: 60px;
  font-size: 18px;
  font-weight: bold;
`;

type PaymentProps = {
  goNext: Function;
};

const Payment = ({ goNext }: PaymentProps) => {
  const { t } = useTranslation();
  const {
    chosenArmDevice,
    clearBasket,
    basket,
    currencyCode,
    visualProfileColors,
    getComposerTiles,
    setLastSale
  } = useContext(GlobalContext);

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const initiatePaymentProcess = async () => {
    if (!chosenArmDevice) {
      goNext({ paymentStatusData: PaymentStatus.Failure });
      return;
    }

    const data: PaymentServiceDataType = {
      type: chosenArmDevice.type,
      basketItems: basket.basketItems,
      totalPrice: basket.totalPrice,
      currency: currencyCode,
      armDevice: chosenArmDevice
    };

    let isOK = true;
    let saleResponse;
    try {
      const { submitPayment, completePayment } = await getPaymentService(data);
      const paymentSubmit = await submitPayment();
      const { terminalStatus, paymentDetails } = paymentSubmit;
      if (terminalStatus === 0) {
        saleResponse = await putSales({
          basketId: basket.externalUid,
          saleItems: basket.basketItems,
          paymentDetails,
          armId: data?.armDevice.id.toString()
        });
        await completePayment(saleResponse);
      } else {
        // eslint-disable-next-line no-console
        console.warn('Not IsCompleted AND terminalStatus 0');
        isOK = false;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('exception:', e);
      isOK = false;
    } finally {
      getComposerTiles();
      if (isOK) {
        setLastSale(saleResponse);
        clearBasket(true);
        goNext({
          paymentStatusData: PaymentStatus.Success,
          saleResponseData: saleResponse
        });
      } else {
        goNext({ paymentStatusData: PaymentStatus.Failure });
      }
    }
  };

  useEffect(() => {
    initiatePaymentProcess();
  }, []);

  return (
    <BasketModalContent title={t('Basket.Cart')} styles={styles}>
      <Wrapper>
        <Title>{useTranslation().t('Basket.InstructionsOnTerminal')}</Title>
        <Loader width={200} color="black" />
      </Wrapper>
    </BasketModalContent>
  );
};

export default Payment;
