import { useEffect, useState } from 'react';
import {
  BasketType,
  BasketItem,
  GridItemProps,
  GridItemType,
  OrderCustomization
} from '../types/basket.type';
import { BasketStateType } from '../types/global-state-data.type';
import {
  getNamedLocalStorage,
  setNamedLocalStorage
} from '../utils/namedLocalStorage';
import {
  getBasketById,
  postCreateBasket,
  patchRemoveItemsById,
  patchRemoveAllItemsByBasketId,
  patchAddItemsByBasketId,
  patchUpdateItemsByBasketId
} from '../services/basket.service';
import { isTemplateExpression } from 'typescript';


const emptyBasket: BasketType = {
  externalUid: '',
  totalPrice: 0,
  totalQuantity: 0,
  basketItems: [],
  completedTime: ""
};

const calculateTotal = (
  basketItems: BasketItem[]
): { totalPrice: number; totalQuantity: number } => {
  let totalPrice = 0;
  let totalQuantity = 0;
  basketItems.forEach(item => {
    if (item.quantity > 0) {
      const itemPrice = item.price || 0;

      totalPrice += itemPrice * item.quantity;
      totalQuantity += item.quantity;
    }
  });
  return { totalPrice, totalQuantity };
};

const useBasketState = (): BasketStateType => {
  const [basketLoading, setBasketLoading] = useState<boolean>(true);
  const [basket, setBasket] = useState<BasketType>(emptyBasket);
  const [basketId, setBasketId] = useState<string>('');

  const getInitialBasket = async (): Promise<void> => {
    const basketJson = getNamedLocalStorage('basket');
    const bask = basketJson ? JSON.parse(basketJson) : {};
    let result;
    setBasketLoading(true);

    if (bask.externalUid) {
      result = await getBasketById(bask.externalUid)
      if (result.completedTime != null)
      {
        // This should not happen (unless the UI times out when it was waiting for the basket completed message)
        // So we are hiding the UI message ( as its information only), if we want to display it correctly(language) we need to set a state variable
        // and have this configured to display the message on the startup screen, which seems like overkill
        // notify({
        //   message: `${('Basket has been completed successfully, new basket created ')}`,
        //   type: 'error'
        // });

        result = await postCreateBasket();
      }
    }
     else {
      result = await postCreateBasket();
  
      }
    setBasketId(result.externalUid as string);
    setNamedLocalStorage('basket', JSON.stringify(result));
    setBasket(result);
    setBasketLoading(false);
    
  };
  
  const getBasketItems = (
    referenceId?: number,
    type?: GridItemType
  ): BasketItem[] => {
    return basket.basketItems.filter(
      (basketItem: BasketItem) =>
        basketItem.referenceId === referenceId && basketItem.type === type
    );
  };

  const updateBasket = (basketToUpdate: BasketType) => {
    const { totalPrice, totalQuantity } = calculateTotal(
      basketToUpdate.basketItems
    );

    const updatedBasket = {
      externalUid: basket.externalUid,
      basketItems: basketToUpdate.basketItems,
      totalPrice,
      totalQuantity
    };
    setBasket(updatedBasket);
    setNamedLocalStorage('basket', JSON.stringify(updatedBasket));
  };

  const updateBasketItem = async (
    id: number,
    quantity: number,
    customization: OrderCustomization = {}
  ) => {
    const basketItemToUpdate = basket.basketItems.find(
      item => item.id === id
    ) as BasketItem;
    const newBasketItem = {
      ...basketItemToUpdate,
      bundleSelections: basketItemToUpdate.customization?.bundleSelections,
      modifierSelections: basketItemToUpdate.customization?.modifierSelections,
      quantity
    };
    setBasketLoading(true);
    const newBasket = await patchUpdateItemsByBasketId(basketId, newBasketItem);
    updateBasket(newBasket);
    setBasketLoading(false);
  };

  const addBasketItem = async (
    newItem: GridItemProps,
    customization: OrderCustomization = {},
    newQuantity: number = 1
    ) => {
      let newBasket : BasketType;
      const getBasketItem = basket?.basketItems?.find(i => i.referenceId == newItem.referenceId);
      setBasketLoading(true);
      // For a simple non customisable item if we have a basket line already for that item add it 
      if ((getBasketItem) && (newItem.quantity) && (!newItem.value.isCustomizable))
        {
            getBasketItem.quantity = getBasketItem.quantity  + 1; // the qty in new Item will be set to the existing qty
            newBasket = await patchUpdateItemsByBasketId(basketId,getBasketItem )
        }
      else{
      newBasket = await patchAddItemsByBasketId(basketId, {
        id: newItem.id,
        referenceId: newItem.referenceId || 0,
        type: newItem.type,
        label: newItem.label,
        quantity: newQuantity || 1,
        ...customization
      });
    }

      setBasket(newBasket);
      setNamedLocalStorage('basket', JSON.stringify(newBasket));
      setBasketLoading(false);
    
    
  };

  const removeBasketItem = async (id: number) => {
    setBasketLoading(true);
    const newBasket = await patchRemoveItemsById(basketId, id);
    updateBasket(newBasket);
    setBasketLoading(false);
  };

  const clearBasket = async (clearBasketId: boolean = false) => {
    let newBasket;
    setBasketLoading(true);
    if (clearBasketId) {
      setNamedLocalStorage('basket', JSON.stringify(emptyBasket));
      await getInitialBasket();
    } else {
      newBasket = await patchRemoveAllItemsByBasketId(basketId);
      setBasket(newBasket);
    }
    setBasketLoading(false);
  };

  useEffect(() => {
    getInitialBasket();
  }, []);

  return {
    basket,
    basketLoading,
    addBasketItem,
    updateBasketItem,
    getBasketItems,
    removeBasketItem,
    clearBasket,
    updateBasket
  };
};

export default useBasketState;
