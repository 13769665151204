import React, { useState, useEffect, useContext } from 'react';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import {
  Grid as GridComponent,
  notify
} from '@k3imagine/self-serve-components';
import { navigate } from 'gatsby';
import GlobalContext from '../../state/GlobalContext';
import {
  UpdateBasketItemInfo,
  BasketItem,
  GridItemProps,
  Breadcrumb,
  RootComposerGroup
} from '../../types';
import { LayoutContext } from '..';
import { debounce } from 'lodash';

const getTotalQuantity = (
  product: GridItemProps,
  basketItems: BasketItem[]
) => {
  let totalQuantity = 0;
  basketItems.forEach(basketItem => {
    if (
      basketItem.referenceId === product.referenceId &&
      basketItem.type === product.type
    ) {
      totalQuantity += basketItem.quantity;
    }
  });

  return totalQuantity;
};

const updateQuantity = (
  gridItems: GridItemProps[],
  basketItems: BasketItem[]
) => {
  return gridItems.map(item => ({
    ...item,
    quantity: getTotalQuantity(item, basketItems)
  }));
};

const filterByGroupId = (
  groupId: string | number,
  allComposerTiles: GridItemProps[]
): GridItemProps[] => {
  return allComposerTiles.filter(
    item => item.composerGroupId === Number(groupId)
  );
};

const filterAllGroups = (
  allComposerTiles: GridItemProps[]
): GridItemProps[] => {
  let mappedGridItems: GridItemProps[] = [];
  allComposerTiles.forEach(item => {
    if (
      item.type === 'Group' &&
      !mappedGridItems.find(i => i.referenceId === item.referenceId)
    ) {
      mappedGridItems = [...mappedGridItems, item];
    }
  });
  return mappedGridItems;
};

const getBreadcrumbsFromUrl = (
  composerTiles: GridItemProps[],
  pathname: string,
  rootGroup?: RootComposerGroup
): Breadcrumb[] => {
  const groupsFromPath: string[] = pathname.split('/').slice(2);
  if (groupsFromPath.length <= 0) {
    navigate('/');
  }

  let newBreadcrumbs: Breadcrumb[] = [];
  let foundGroup;

  groupsFromPath.forEach(groupUrlName => {
    switch (groupUrlName) {
      case 'all':
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            id: uniqid(),
            label: useTranslation().t('AllGroups'),
            value: { groupUrlName: 'all', referenceId: 'all' }
          }
        ];
        return;
      case rootGroup?.groupUrlName:
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            id: uniqid(),
            label: rootGroup?.displayName || '',
            value: {
              groupUrlName: rootGroup?.groupUrlName,
              referenceId: rootGroup?.id
            }
          }
        ];
        return;
      default:
        foundGroup = composerTiles.find(
          g => g.value.groupUrlName === groupUrlName
        );
        // if we find a url group that does not match a groupUrlName, we will navigate back to home
        if (!foundGroup) {
          navigate('/');
          return [
            {
              id: uniqid(),
              label: rootGroup?.displayName || '',
              value: {
                groupUrlName: rootGroup?.groupUrlName,
                referenceId: rootGroup?.id
              }
            }
          ];
        }
        newBreadcrumbs = [
          ...newBreadcrumbs,
          {
            id: uniqid(),
            label: foundGroup?.label || '',
            value: {
              groupUrlName: foundGroup.value.groupUrlName,
              referenceId: foundGroup.referenceId
            }
          }
        ];
    }
  });

  return newBreadcrumbs;
};

export const Grid = ({ location }: { location: Location }) => {
  const [filteredItems, setFilteredItems] = useState<GridItemProps[]>([]);
  const [gridItems, setGridItems] = useState<GridItemProps[]>([]);
  const {
    setShowProductDetailModal,
    setProductDetailModalInfo,
    setShowBasketModal,
    setBreadcrumbs
  } = useContext(LayoutContext);
  const {
    basket,
    currencyCode,
    composerTiles,
    getBasketItems,
    removeBasketItem,
    addBasketItem,
    updateBasketItem,
    visualProfileColors,
    rootComposerGroup,
    defaultLocale
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const styles = {
    item: {
      group: {
        backgroundColor: visualProfileColors?.categoryTile?.background,
        labelTextColor: visualProfileColors?.categoryTile?.text
      }
    }
  };

  const newBreadcrumbs: Breadcrumb[] = getBreadcrumbsFromUrl(
    composerTiles,
    location.pathname,
    rootComposerGroup
  );

  // last breadcrumb is the current group we want to see
  const groupId =
    newBreadcrumbs.length > 0
      ? newBreadcrumbs[newBreadcrumbs.length - 1].value.referenceId
      : rootComposerGroup?.id;

  useEffect(() => {
    setBreadcrumbs(newBreadcrumbs);

    let items: GridItemProps[];
    if (groupId === 'all') {
      items = filterAllGroups(composerTiles);
    } else {
      items = filterByGroupId(groupId, composerTiles);
    }
    setFilteredItems(items);
  }, [composerTiles, groupId]);

  useEffect(() => {
    setGridItems(updateQuantity(filteredItems, basket.basketItems));
  }, [basket, filteredItems]);

  const handleNumberPickerChange = async ({
    actionType,
    item
  }: UpdateBasketItemInfo) => {
    if (actionType === '+') {
      if (item.value.isCustomizable) {
        setProductDetailModalInfo({ product: item, isNew: true });
        setShowProductDetailModal(true);
      } else {
        await addBasketItem(item);
      }
    }
    if (actionType === '-') {
      const basketItems = getBasketItems(item.referenceId, item.type);
      if (basketItems.length > 1) {
        notify({
          message: 'Please choose which item you want to remove.',
          type: 'error'
        });
        setShowBasketModal(true);
      } else if (basketItems.length === 1) {
        if (item.quantity && item.quantity <= 1) {
          removeBasketItem(basketItems[0].id);
        } else {
          updateBasketItem(basketItems[0].id, basketItems[0].quantity - 1);
        }
      }
    }
  };


  return (
    <>
      <GridComponent
        gridItems={gridItems}
        styles={styles}
        currencyCode={currencyCode}
        emptyGridMessage={t('EmptyGridMessage')}
        outOfStockText={t('SoldOut')}
        locale={defaultLocale}
        onNumberPickerChanged={(info: UpdateBasketItemInfo) =>
          handleNumberPickerChange(info)
        }
        onItemClicked={(item: GridItemProps) => {
          if (item.type === 'Group' && item.referenceId) {
            navigate(`${location.pathname}/${item.value.groupUrlName}`);
          } else if (item.hasInfo) {
            setProductDetailModalInfo({ product: item, isNew: true });
            setShowProductDetailModal(true);
          }
        }}
      />
    </>
  );
};

export default Grid;
