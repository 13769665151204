import Cookies from 'js-cookie';
import tldjs from 'tldjs';
import { CookieTypes } from '../types/cookie-token.type';

const getURLDomain = (): string => {
  const hostName = tldjs.getDomain(window.location.href);

  return hostName != null ? `.${hostName}` : '';
};

export const getCookie = (cookieName: CookieTypes): string | undefined => {
  return Cookies.get(cookieName);
};

export const removeCookie = (cookieName: CookieTypes) => {
  return Cookies.remove(cookieName);
};

export const setCookie = (cookieName: CookieTypes, value: any) => {
  return Cookies.set(cookieName, value, { domain: getURLDomain() });
};
