import { AxiosResponse } from 'axios';
import { PaymentServiceType } from '../../payment.type';
import * as VirtualTypes from './virtual-payment.type';

const paymentDetails = {
  cardPan: 'VirtualTypes',
  cardName: 'Virtual',
  authorizationCode: '123',
  acquiringBankId: '123'
};

const mockData: PaymentServiceType = {
  terminalStatus: 0,
  completed: true,
  failed: false,
  paymentDetails
};

const mockPromise: AxiosResponse = {
  data: mockData,
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {}
};

const completePayment = () =>
  Promise.resolve({
    ...mockPromise
  });

const reprintCompleteReceipt = () =>
  Promise.resolve({
    ...mockPromise
  });

export const submitPayment = async (): Promise<PaymentServiceType> => mockData;

export const virtualPaymentService = (): VirtualTypes.VirtualPaymentServiceType => ({
  submitPayment: () => submitPayment(),
  completePayment: () => completePayment(),
  reprintCompleteReceipt: () => reprintCompleteReceipt()
});
