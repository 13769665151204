import React, { useContext, useState, useEffect } from 'react';
import { Loader, BasketModalContent } from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';

import * as S from './BasketValidation.styles';
import GlobalContext from '../../../../state/GlobalContext';
import { getPaymentBreakdown } from '../../../../services/sales.service';
import { basketValidation } from '../../../../services/event.service';
import { PaymentStatus, BasketItem, SaleItem } from '../../../../types';
import NoBasketItemsAvailable from './NoBasketItemsAvailable/NoBasketItemsAvailable';
import SomeBasketItemsNotAvailable from './SomeBasketItemsNotAvailable/SomeBasketItemsNotAvailable';
import ServiceNotAvailable from './ServiceNotAvailable/ServiceNotAvailable';

type BasketValidationProps = {
  goNext: Function;
  goBack: Function;
  goToStart: Function;
  goToPaymentStatus: Function;
};

const BasketValidation = ({
  goNext,
  goBack,
  goToStart,
  goToPaymentStatus
}: BasketValidationProps) => {
  const [availableBasketItems, setAvailableBasketItems] = useState<
    BasketItem[]
  >([]);
  const [unavailableBasketItems, setUnavailableBasketItems] = useState<
    BasketItem[]
  >([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [serviceNotAvailable, setServiceNotAvailable] = useState<boolean>(
    false
  );
  const {
    basket,
    updateBasket,
    visualProfileColors,
    getComposerTiles,
    performBasketValidationEvent
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const mapSaleItemsToBasketItems = (saleItems: SaleItem[]) => {
    let basketItems: BasketItem[] = [];
    saleItems.forEach((i: SaleItem) => {
      const foundBasketItem = basket.basketItems.find(
        b => b.id === i.localStorageBasketItemIdentifier
      );
      if (foundBasketItem) {
        basketItems = [
          ...basketItems,
          {
            ...foundBasketItem,
            quantity: i.quantity,
            price: foundBasketItem.price
          }
        ];
      }
    });

    return basketItems;
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if (performBasketValidationEvent) {
        const basketId = basket.basketItems[0].id.toString();

        const basketValidationResponse = await basketValidation({ basketId });
        if (basketValidationResponse?.data?.stopProgression) {
          setIsLoading(false);
          setServiceNotAvailable(true);
          return;
        }
      }

      const response = await getPaymentBreakdown(
        basket.basketItems,
        basket.externalUid
      );
      if (response.status >= 400) {
        goToPaymentStatus({ paymentStatus: PaymentStatus.Failure });
        return;
      }

      // if there are no unavailable basket items, we go straight to next step
      if (
        !response?.data?.unavailableBasketItems ||
        response?.data?.unavailableBasketItems.length <= 0
      ) {
        goNext(response?.data?.totalAmount || 0);
        return;
      }

      setIsLoading(false);
      setTotalAmount(response?.data?.totalAmount);

      const mappedAvailableBasketItems = mapSaleItemsToBasketItems(
        response?.data?.availableBasketItems || []
      );
      setAvailableBasketItems(mappedAvailableBasketItems);
      updateBasket(mappedAvailableBasketItems);
      setUnavailableBasketItems(
        mapSaleItemsToBasketItems(response?.data?.unavailableBasketItems || [])
      );
    })();
  }, []);

  const goBackAfterBasketValidation = () => {
    getComposerTiles();
    goBack();
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <S.LoaderWrapper>
          <Loader width={200} color="black" />
        </S.LoaderWrapper>
      );
    }

    if (serviceNotAvailable) {
      return <ServiceNotAvailable />;
    }
    // if no available items
    if (!availableBasketItems || availableBasketItems.length <= 0) {
      return (
        <NoBasketItemsAvailable
          unavailableBasketItems={unavailableBasketItems}
          goToStart={goToStart}
        />
      );
    }

    return (
      <SomeBasketItemsNotAvailable
        totalAmount={totalAmount}
        availableBasketItems={availableBasketItems}
        unavailableBasketItems={unavailableBasketItems}
      />
    );
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.Cart')}
      onIconButtonClicked={goBackAfterBasketValidation}
      leftButtonIcon="BackArrow"
      buttonLabel={t('BasketValidation.ContinueToPayment')}
      onButtonClicked={() => goNext()}
      buttonDisabled={availableBasketItems.length <= 0}
    >
      {renderContent()}
    </BasketModalContent>
  );
};

export default BasketValidation;
