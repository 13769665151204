import React from 'react';
import { Error } from '@k3imagine/self-serve-components';

const ErrorPage = ({
  title,
  message
}: {
  title?: string;
  message?: string;
}) => <Error title={title} message={message} icon="Spaceship" />;

export default ErrorPage;
