import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import * as S from './InactivityModal.styles';
import GlobalContext from '../../state/GlobalContext';

const InactivityModal = ({ showModal }: { showModal: boolean }) => {
  const initialIdleSeconds = 30;
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState<number>(0);
  const { clearBasket } = useContext(GlobalContext);

  // We will set initial seconds on open modal and set to 0 to stop timer on close
  useEffect(() => {
    if (showModal) {
      setSeconds(initialIdleSeconds);
    }
    if (!showModal) {
      setSeconds(0);
    }
  }, [showModal]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 1) {
        clearBasket();
        setSeconds(0);
        navigate('/');
      }
      if (seconds > 1) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <S.ModalBackground showModal={showModal}>
      <S.Modal>
        <S.ModalTitle>{t('AreYouStillHere')}</S.ModalTitle>
        {seconds && (
          <S.ModalDescription>
            {t('WeWillResetYourBasketInXSeconds', { seconds })}
          </S.ModalDescription>
        )}

        <S.ContinueButton>{t('IAmStillHere')}</S.ContinueButton>
      </S.Modal>
    </S.ModalBackground>
  );
};

export default InactivityModal;
