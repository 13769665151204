import { useState, useEffect } from 'react';
import {
  GlobalStateType,
  GridItemProps,
  ComposerGroupItem,
  ArmDevice,
  VisualProfileColors,
  Font,
  RootComposerGroup,
  SalesResponse
} from '../types';
import { getAllComposerTiles } from '../services/composer.service';
import { getShop } from '../services/shops.service';
import {
  getContextShopId,
  getTenantId,
  hasValidToken,
  logRocketEnabled
} from '../services/authentication.service';
import { getConfig } from '../services/config.service';
import { isSupportedLanguage } from '../locales/i18n';
import {
  getNamedLocalStorage,
  setNamedLocalStorage
} from '../utils/namedLocalStorage';
import { PaymentProviderEnum } from '../services/payment/payment.type';

const VIRTUAL_DEVICE = {
  id: 108932169,
  type: PaymentProviderEnum.Virtual,
  name: 'Virtual',
  url: 'virtual',
  mac: 'virtual'
};

const DEFAULT_COUNTRY = 'gb';
const DEFAULT_LOCALE = 'en-gb';

const mapGridItems = (composerTiles: ComposerGroupItem[] = []) =>
  composerTiles.map(
    ({
      displayName,
      priceRange,
      groupUrlName,
      isCustomizable,
      ...rest
    }: ComposerGroupItem) => ({
      ...rest,
      label: displayName,
      price: priceRange ? priceRange.from : 0,
      value: {
        groupUrlName: groupUrlName || '',
        isCustomizable
      }
    })
  );

const getCountryFromLocale = (locale: string) => {
  const country = locale.split('-')[1];
  return isSupportedLanguage(country) ? country : DEFAULT_COUNTRY;
};

const useGlobalState = (): GlobalStateType => {
  const [composerTiles, setComposerTiles] = useState<GridItemProps[]>([]);
  const [rootComposerGroup, setRootComposerGroup] = useState<
    RootComposerGroup
  >();
  const [isFetching, setFetching] = useState<boolean>(true);
  const [currencyCode, setCurrencyCode] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [initLoadCompleted, setInitLoadCompleted] = useState<boolean>(false);
  const [shopId, setShopId] = useState<string>();
  const [defaultLocale, setDefaultLocale] = useState<string>(DEFAULT_LOCALE);
  const [visualProfileColors, setVisualProfileColors] = useState<
    VisualProfileColors
  >();
  const [visualProfileFonts, setVisualProfileFonts] = useState<Font>();
  const [startScreenImage, setStartScreenImage] = useState<string>(
    'https://k3imagine.blob.core.windows.net/selfserv/K3imagine-logo-dark.png'
  );

  // State used for Language Picker
  const [initialCountry, setInitialCountry] = useState<string>(DEFAULT_COUNTRY);
  const [currentCountry, setCurrentCountry] = useState<string>(DEFAULT_COUNTRY);
  const [supportedLanguages, setSupportedLanguages] = useState<string[]>([
    DEFAULT_LOCALE
  ]);

  const [clerkNumberPrefix, setClerkNumberPrefix] = useState<string>();
  const [availableArmDevices, setAvailableArmDevices] = useState<ArmDevice[]>([
    VIRTUAL_DEVICE
  ]);
  const armFromLocalStorage = getNamedLocalStorage('armDevice');
  const [chosenArmDevice, setChosenArmDevice] = useState<ArmDevice>(
    armFromLocalStorage ? JSON.parse(armFromLocalStorage) : undefined
  );
  const [idleResetMilliseconds, setIdleResetMilliseconds] = useState<number>(
    30000
  );
  const [hideOrderWaitingTime, setHideOrderWaitingTime] = useState<boolean>(
    false
  );

  const [
    performBasketValidationEvent,
    setPerformBasketValidationEvent
  ] = useState<boolean>(false);

  const refreshKiosk = () => {
    setLoading(true);
    setInitLoadCompleted(false);
    setFetching(true);
  };

  const getComposerTiles = async () => {
    const isLoggedIn = await hasValidToken();
    if (isLoggedIn) {
      const responses = await Promise.all([
        getAllComposerTiles(),
        getShop(),
        getConfig()
      ]);
      if (responses.every((r: any) => r.status < 400 && r.data)) {
        const [composer, shop, config] = responses;
        const { data: composerData } = composer;
        const { data: shopData } = shop;
        const { imageUrl: image, languages, currency } = shopData;
        const { data: configData } = config;
        const defaultLanguage =
          languages?.find(lang => lang.defaultLanguage)?.languageCode ||
          DEFAULT_LOCALE;
        const supportedLangs = languages.map(lang => lang.languageCode);

        setSupportedLanguages(supportedLangs);
        const gridItems: GridItemProps[] = mapGridItems(composerData.tiles);
        setRootComposerGroup(composerData.rootComposerGroup);
        setComposerTiles(gridItems);
        setCurrencyCode(currency ? currency.currencyCode : '');
        setImageUrl(image || '');
        setDefaultLocale(defaultLanguage);
        setVisualProfileColors(configData?.profile?.color);
        setVisualProfileFonts(configData?.profile?.font);
        setHideOrderWaitingTime(configData?.hideOrderWaitingTime || false);
        setPerformBasketValidationEvent(
          configData?.performBasketValidationEvent || false
        );
        if (configData?.profile?.asset?.startScreenImages[0]?.url) {
          setStartScreenImage(
            configData.profile.asset.startScreenImages[0].url
          );
        }

        const country = getCountryFromLocale(defaultLanguage);
        setInitialCountry(country);
        setCurrentCountry(country);

        setClerkNumberPrefix(configData.clerkNumberPrefix);
        setIdleResetMilliseconds(configData.idleResetSeconds * 1000 || 30000);
        setAvailableArmDevices([...configData.armDevices, VIRTUAL_DEVICE]);
        setFetching(false);
      } else {
        setError(true);
      }
    }
  };

  useEffect(() => {
    setShopId(getContextShopId() || 'NO_SHOP_ID');

    if (initLoadCompleted) {
      setFetching(false);
      setLoading(false);
    } else {
      getComposerTiles();
      setInitLoadCompleted(true);
    }
  }, [isFetching]);

  const getLastSale = (): SalesResponse | null => {
    const v = getNamedLocalStorage('lastSale');
    return v ? JSON.parse(v) : v;
  };

  const setLastSale = (v: string) =>
    setNamedLocalStorage('lastSale', JSON.stringify(v));

  const isLogRocketEnabled =  logRocketEnabled();

  const tenantId=  getTenantId();

  return {
    error,
    loading,
    imageUrl,
    currencyCode,
    composerTiles,
    rootComposerGroup,
    shopId,
    defaultLocale,
    initialCountry,
    currentCountry,
    setCurrentCountry,
    supportedLanguages,
    clerkNumberPrefix,
    idleResetMilliseconds,
    availableArmDevices,
    chosenArmDevice,
    setChosenArmDevice,
    refreshKiosk,
    visualProfileColors,
    visualProfileFonts,
    startScreenImage,
    hideOrderWaitingTime,
    getComposerTiles,
    performBasketValidationEvent,
    getLastSale,
    setLastSale,
    isLogRocketEnabled,
    tenantId
  };
};

export default useGlobalState;
