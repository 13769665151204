import { armPaymentService } from './payments/armPayment/arm-payment.service';
import { virtualPaymentService } from './payments/virtualPayment/virtual-payment.service';
import {
  PaymentProviderEnum,
  PaymentServiceDataType,
  PaymentServiceType
} from './payment.type';

export const PaymentProviderMap = {
  [PaymentProviderEnum.Arm]: armPaymentService,
  [PaymentProviderEnum.Virtual]: virtualPaymentService
};

export const requestRepeat = (
  requestFunction: () => Promise<PaymentServiceType>,
  retries: number = 50
): any => {
  return requestFunction().then(response => {
    if (response.terminalStatus >= 0) {
      return response;
    }
    if (retries > 0) {
      return requestRepeat(requestFunction, retries - 1);
    }
  });
};

export const getPaymentService = (data: PaymentServiceDataType) =>
  PaymentProviderMap[data.type](data);
