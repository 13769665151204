import { createContext } from 'react';
import { GlobalContextType } from '../types/global-state-data.type';

const GlobalContext = createContext<GlobalContextType>({
  basket: { totalPrice: 0, totalQuantity: 0, basketItems: [] },
  composerTiles: [],
  currencyCode: '',
  imageUrl: '',
  loading: false,
  error: false,
  idleResetMilliseconds: 30000,
  availableArmDevices: [],
  getBasketItems: () => {},
  addBasketItem: () => {},
  updateBasketItem: () => {},
  removeBasketItem: () => {},
  clearBasket: () => {},
  setChosenArmDevice: () => {},
  refreshKiosk: () => {}
});

export default GlobalContext;
