import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../BasketValidation.styles';

const ServiceNotAvailable = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.Title>{t('Error.Oooops')}</S.Title>
      <S.SubTitle>{t('Error.ServiceUnavailable')}</S.SubTitle>
      <S.SubTitle>{t('Error.ContactMemberOfStaff')}</S.SubTitle>
    </>
  );
};

export default ServiceNotAvailable;
