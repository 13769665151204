import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, BasketList } from '@k3imagine/self-serve-components';

import GlobalContext from '../../../../../state/GlobalContext';
import * as S from '../BasketValidation.styles';
import { BasketItem } from '../../../../../types';

type NoBasketItemsAvailableProps = {
  unavailableBasketItems: BasketItem[];
  goToStart: Function;
};

const ButtonWrapper = styled.div`
  margin: 10% auto;
  width: 50%;
`;

const NoBasketItemsAvailable = ({
  unavailableBasketItems,
  goToStart
}: NoBasketItemsAvailableProps) => {
  const {
    currencyCode,
    defaultLocale,
    visualProfileColors,
    getComposerTiles
  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const goToStartAndFetchNewTiles = () => {
    getComposerTiles();
    goToStart();
  };

  return (
    <>
      <S.Title>{t('Error.Oooops')}</S.Title>
      <S.Title>{t('BasketValidation.AllItemsHaveSoldOut')}</S.Title>
      <ButtonWrapper>
        <Button
          label={t('BasketValidation.GoBackToStart')}
          onClicked={goToStartAndFetchNewTiles}
          styles={visualProfileColors?.button?.primary}
        />
      </ButtonWrapper>
      <S.SubTitle>
        {t('BasketValidation.TheseAreTheItemsNotAvailable')}
      </S.SubTitle>
      <S.BasketListWrapper>
        <BasketList
          basketItems={unavailableBasketItems}
          currencyCode={currencyCode}
          locale={defaultLocale}
        />
        <S.NotAvailableOverlay />
      </S.BasketListWrapper>
    </>
  );
};

export default NoBasketItemsAvailable;
