import { ArmDataType } from './payments/armPayment/arm-payment.type';
import { VirtualDataType } from './payments/virtualPayment/virtual-payment.type';
import { BasketItem } from '../../types/basket.type';

export enum PaymentProviderEnum {
  Arm = 'Arm',
  Virtual = 'Virtual'
}

type PaymentProviderTypeMap = ArmDataType | VirtualDataType;

export type PaymentServiceDataType = {
  basketItems: BasketItem[];
  totalPrice: number;
  currency: string;
} & PaymentProviderTypeMap;

export type Payment = { sessionId: number };

export enum TerminalStatusResult {
  Information = -1,
  Success = 0,
  Failure = 2,
  Authorised = -3
}

export type PaymentServiceBasketItem = BasketItem & {
  id: number;
  price: number;
  label?: string;
  vat: number;
  itemNo: string;
  quantity: number;
};

export type PaymentDetails = {
  cardPan?: string;
  cardName?: string;
  authorizationCode?: string;
  acquiringBankId?: string;
};

export type PaymentServiceType = {
  terminalStatus: TerminalStatusResult;
  completed: boolean;
  paymentDetails?: PaymentDetails;
  failed: boolean;
};
