import React, { useEffect, useState, useContext } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Router, RouteComponentProps } from '@reach/router';
import { Helmet } from 'react-helmet';
import { Toaster } from '@k3imagine/self-serve-components';

import * as AuthService from '../services/authentication.service';
import GlobalContext from '../state/GlobalContext';
import { StartScreen, Grid, Layout, ErrorScreen } from '../components';
import LogRocket from 'logrocket';

type RouteProps = {
  component: React.ComponentType<any>;
  children?: React.ReactNode;
} & RouteComponentProps;

const ProtectedRoute = ({
  component: Component,
  path,
  children,
  ...rest
}: RouteProps) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  useEffect(() => {
    const checkForAuthentication = async () => {
      setIsAuthorized(await AuthService.isLoggedIn());
    };
    checkForAuthentication();
  }, []);

  return (
    <>{isAuthorized ? <Component {...rest}>{children}</Component> : null}</>
  );
};

const App = () => {
  const { shopId, error, visualProfileFonts, tenantId,isLogRocketEnabled  } = useContext(GlobalContext);
  const { t } = useTranslation();

  const GlobalStyles = createGlobalStyle`
  @import url(${visualProfileFonts?.stylesheetUrl});
    * {
      font-family: ${visualProfileFonts?.name ||
        'Source Sans Pro'}, 'Source Sans Pro';
    }

    body {
     margin: 0px;
    }
`;

  const renderContent = () => {
    if (shopId === 'NO_SHOP_ID') {
      return (
        <ErrorScreen
          title={t('Error.Oooops')}
          message={t('Error.NoShopIdProvided')}
        />
      );
    }

    if (error) {
      return (
        <ErrorScreen
          title={t('Error.Oooops')}
          message={t('Error.UnexpectedError')}
        />
      );
    }

    React.useEffect(() => {

    if(isLogRocketEnabled)
      { 
        LogRocket.init(`${process.env.LOGROCKET_IDENTIFIER}`??"");
        LogRocket.identify(tenantId + "-" + shopId, {
          userName: "Kiosk",
          tenantId: tenantId ?? "0",
          userId: 0,
          surname: "N/A",
          shopId: shopId || "N/A",
          module: "kiosk"
        });
      }

    }, []);

    return (
      <>
        <GlobalStyles />
        <Router>
          <ProtectedRoute path="/" component={StartScreen} />
          <ProtectedRoute path="/groups/*" component={Layout}>
            <ProtectedRoute path="/" component={Grid} />
          </ProtectedRoute>
        </Router>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Kiosk</title>
      </Helmet>
      {renderContent()}
      <Toaster />
    </>
  );
};

export default App;
