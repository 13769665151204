import React, { useState, ComponentProps, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import {
  LanguagePicker,
  Image,
  FlagIcon,
  notify
} from '@k3imagine/self-serve-components';

import * as S from './StartScreen.styles';
import i18n from '../../locales/i18n';
import GlobalContext from '../../state/GlobalContext';

export type FlagIconProps = ComponentProps<typeof FlagIcon>['code'];

const DEFAULT_IMAGE =
  'https://k3imagine.blob.core.windows.net/selfserv/K3imagine-logo-dark.png';

const StartScreen = () => {
  const {
    imageUrl,
    initialCountry,
    currentCountry,
    supportedLanguages,
    setCurrentCountry,
    visualProfileColors,
    rootComposerGroup,
    startScreenImage,
    getComposerTiles,
    chosenArmDevice
  } = useContext(GlobalContext);
  const [isInitialized, setInitialized] = useState(false);
  const [showFlags, setShowFlags] = useState<boolean>(false);
  const { t } = useTranslation();

  const availableLanguages: {
    code: FlagIconProps;
  }[] = supportedLanguages.map((language: string) => ({
    code: language.split('-')[1] as FlagIconProps
  }));
  const changeLanguage = (country: string) => {
    i18n.changeLanguage(country);
    setCurrentCountry(country);
  };

  const toggleFlags = () => {
    setShowFlags(!showFlags);
  };

  const toggleScreen = () => {
    navigate(`/groups/${rootComposerGroup?.groupUrlName}`);
    getComposerTiles();
    setShowFlags(false);
  };

  useEffect(() => {
    if (!chosenArmDevice) {
      notify({
        message: `${t('Error.NoPaymentDeviceSelectedToaster')}`,
        type: 'error',
        autoClose: false
      });
    }
  }, []);

  useEffect(() => {
    function initialize() {
      if (currentCountry !== initialCountry) {
        changeLanguage(initialCountry);
      }
      setInitialized(true);
    }
    initialize();
  }, []);

  if (!isInitialized) {
    return null;
  }

  return (
    <>
      <S.ScreenWrapper onClick={toggleScreen}>
        <Image
          width="100%"
          height="80vh"
          src={startScreenImage || DEFAULT_IMAGE}
          cover={(startScreenImage || DEFAULT_IMAGE) !== DEFAULT_IMAGE}
        />
      </S.ScreenWrapper>
      <S.ScreenFooter
        color={visualProfileColors?.startScreenFooter?.background || '#fff'}
      >
        <S.Wrapper showFlags={showFlags}>
          {!showFlags && (
            <S.LeftWrapper onClick={toggleScreen}>
              <S.ImageWrapper>
                <Image width="85%" height="85%" src={imageUrl} />
              </S.ImageWrapper>
              <S.TextWrapper
                color={
                  visualProfileColors?.startScreenFooter?.text || '#0058a3'
                }
              >
                {t('PressAnywhereToContinue')}
              </S.TextWrapper>
            </S.LeftWrapper>
          )}
          <S.FlagWrapper>
            <LanguagePicker
              languageOptions={availableLanguages}
              currentLanguage={{
                // Note: The language picker is based on country, not language
                code: currentCountry as FlagIconProps
              }}
              onLanguageChanged={({ code }: { code: FlagIconProps }) => {
                // Note: The language picker is based on country, not language
                changeLanguage(code);
                toggleFlags();
              }}
              onOpen={() => toggleFlags()}
            />
          </S.FlagWrapper>
        </S.Wrapper>
      </S.ScreenFooter>
    </>
  );
};

export default StartScreen;
