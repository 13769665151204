import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  BasketList,
  BasketModalContent
} from '@k3imagine/self-serve-components';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../../../state/GlobalContext';
import { LayoutContext } from '../../../Layout/Layout';
import {
  GridItemProps,
  BasketItem,
  NumberPickerActionType
} from '../../../../types/basket.type';

const ContentWrapper = styled.div`
  height: 100%;
`;

type BasketOverviewProps = {
  goNext: Function;
  closeModal: Function;
  clearBasket: Function;
};

const BasketOverview = ({
  goNext,
  closeModal,
  clearBasket
}: BasketOverviewProps) => {
  const {
    basket,
    currencyCode,
    removeBasketItem,
    defaultLocale,
    updateBasketItem,
    visualProfileColors,
    chosenArmDevice,
    composerTiles,
    basketLoading
  } = useContext(GlobalContext);
  const { setShowProductDetailModal, setProductDetailModalInfo } = useContext(
    LayoutContext
  );
  const { t } = useTranslation();

  const priceSummary = [
    { id: 1, label: t('Basket.Total'), value: basket.totalPrice, bold: true }
  ];

  const styles = {
    bottomButtons: {
      button: visualProfileColors?.button?.primary
    }
  };

  const handleNumberPickerChange = ({
    actionType,
    item
  }: {
    actionType: NumberPickerActionType;
    item: BasketItem;
  }) => {
    if (actionType === '+') {
      updateBasketItem(item.id, item.quantity + 1, item.customization);
    }
    if (actionType === '-') {
      if (item.quantity && item.quantity <= 1) {
        removeBasketItem(item.id);
      } else {
        updateBasketItem(item.id, item.quantity - 1, item.customization);
      }
    }
  };

  const mapBasketItems = (basketItems: BasketItem[]) => {
    if (basketItems && basketItems.length > 0) {
      return basketItems.map(item => {
        const tilesInfo = composerTiles.find(
          tile =>
            tile.referenceId === item.referenceId && tile.type === item.type
        );

        const newItem = { ...tilesInfo, ...item };
        return newItem;
      });
    }
    return [];
  };

  const constructButtonLabel = (totalPrice: number) => {
    return `${t('Basket.Pay')} 
    ${new Intl.NumberFormat(defaultLocale, {
      style: 'currency',
      currency: currencyCode
    }).format(totalPrice)}`;
  };

  return (
    <BasketModalContent
      styles={styles}
      title={t('Basket.Cart')}
      buttonLabel={constructButtonLabel(basket.totalPrice)}
      buttonDisabled={
        !chosenArmDevice ||
        (basket.basketItems && basket.basketItems.length <= 0) ||
        basketLoading
      }
      rightButtonIcon="Trash"
      leftButtonIcon="BackArrow"
      onLeftButtonClicked={closeModal}
      onRightButtonClicked={clearBasket}
      onButtonClicked={() =>
        basket.totalQuantity > 0 && !basketLoading ? goNext() : null
      }
    >
      <ContentWrapper>
        <BasketList
          emptyListText={t('Basket.NoItems')}
          basketItems={mapBasketItems(basket.basketItems)}
          onNumberPickerChanged={(info: {
            actionType: NumberPickerActionType;
            item: BasketItem;
          }) => handleNumberPickerChange(info)}
          onRemoveClicked={({ item }: { item: GridItemProps }) => {
            removeBasketItem(item.id);
          }}
          onEditClicked={({ item }: { item: GridItemProps }) => {
            setProductDetailModalInfo({ product: item, isNew: false });
            setShowProductDetailModal(true);
          }}
          onRowClicked={({ item }: { item: GridItemProps }) => {
            setProductDetailModalInfo({ product: item, isNew: false });
            setShowProductDetailModal(true);
          }}
          summaryListItems={priceSummary}
          currencyCode={currencyCode}
          locale={defaultLocale}
        />
      </ContentWrapper>
    </BasketModalContent>
  );
};

export default BasketOverview;
