import { AxiosResponse } from 'axios';
import api from '../utils/api';
import {
  SaleItem,
  BasketItem,
  SalesResponse,
  PaymentBreakdown
} from '../types';
import { PaymentDetails } from './payment/payment.type';

type SalesBody = {
  basketId: string;
  saleItems: BasketItem[];
  paymentDetails?: PaymentDetails;
  armId?: string;
};

const mapBasketItemsToSalesItems = (basketItems: BasketItem[]): SaleItem[] => {
  return basketItems.map(i => {
    let bundleSelections;
    let modifierSelections;
    if (i.customization?.modifierSelections) {
      modifierSelections = i.customization?.modifierSelections.map(m => ({
        modifierId: m.modifierId,
        quantity: m.quantity
      }));
    }
    if (i.customization?.bundleSelections) {
      bundleSelections = i.customization?.bundleSelections.map(m => ({
        referenceId: m.referenceId,
        bundleGroupId: m.bundleGroupId
      }));
    }

    return {
      localStorageBasketItemIdentifier: i.id,
      referenceId: i.referenceId,
      quantity: i.quantity,
      type: i.type,
      modifierSelections,
      bundleSelections
    };
  });
};

export const putSales = async (
  salesBody: SalesBody
): Promise<SalesResponse> => {
  const saleItems = mapBasketItemsToSalesItems(salesBody.saleItems);

  const { data } = await api.post(
    `/Sales/Basket/${salesBody.basketId}`,
    {
      saleItems,
      paymentDetails: salesBody.paymentDetails,
      basketId: salesBody.basketId,
      armId: salesBody.armId
    },
    {
      baseURL: `${process.env.API_URL}/api/v2.0`
    }
  );
  return data as SalesResponse;
};

export const getPaymentBreakdown = async (
  basketItems: BasketItem[],
  basketId: string
): Promise<AxiosResponse<PaymentBreakdown>> => {
  return api.post(`/payment/breakdown/${basketId}`);
};
