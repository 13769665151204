import { getCookie } from './cookie.store';
import { CookieTypes } from '../types/cookie-token.type';

const getName = (name: string) => {
  const tenantId = getCookie(CookieTypes.TenantId);
  const shopId = getCookie(CookieTypes.ContextShopId);

  return `${tenantId || ''}-${shopId || ''}.${name}`;
};
export const getNamedLocalStorage = (name: string): string | null => {
  if (
    typeof window !== 'undefined' &&
    window.localStorage &&
    localStorage.getItem(getName(name)) !== 'undefined'
  ) {
    return localStorage.getItem(getName(name));
  }
  return null;
};

export const setNamedLocalStorage = (name: string, updatedResource: string) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    localStorage.setItem(getName(name), updatedResource);
  }
};

export const removeNamedLocalStorage = (name: string) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    localStorage.removeItem(getName(name));
  }
};
