import axios, { AxiosResponse } from 'axios';
import { requestRepeat } from '../../payment.service';
import {
  PaymentServiceType,
  PaymentServiceDataType,
  Payment
} from '../../payment.type';
import {
  PaymentRequest,
  ArmPaymentServiceType,
  PrintReceipt
} from './arm-payment.type';
import { composePaymentData, printTranslations } from './arm-payment.utils';

const api = axios.create({
  baseURL: `${process.env.API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

const getArmDeviceUrl = (data: PaymentServiceDataType | any) => {
  return `https://${data.armDevice.url}/POSServiceHandler.aspx`;
};

const printTerminalReceipt = (bonText: string, url: string) => {
  // eslint-disable-next-line
  console.log('ARM - Print Card Receipt');

  api.post(`${url}/PrintCardReceipt`, { receipt: bonText });
};

const makePayment = async (
  url: string,
  paymentRequest: PaymentRequest
): Promise<Payment> => {
  const response = await api.post(`${url}/AddPayment`, { paymentRequest });
  return { sessionId: response?.data?.d?.Result?.Message };
};

const getTerminalStatus = async (
  url: string,
  sessionId: number
): Promise<PaymentServiceType> => {
  const response = await api.post(`${url}/GetTerminalStatus`, { sessionId });

  // if bon text we print card receipt straight away
  if (response?.data?.d?.Result?.BonText) {
    printTerminalReceipt(response?.data?.d?.Result?.BonText, url);
  }

  const responseData = response?.data?.d;
  const completed = responseData.IsCompleted as boolean;
  const terminalStatus = responseData.Result?.Result as number;

  const cardPan = responseData.Result?.CardPan;
  const cardName = responseData.Result?.CardName;
  const authorizationCode = responseData.Result?.AuthorizationId;
  const acquiringBankId = responseData.Result?.AcquiringBankId;
  const paymentDetails = {
    cardPan,
    cardName,
    authorizationCode,
    acquiringBankId
  };

  return { completed, terminalStatus, paymentDetails, failed: completed };
};

const printCompleteReceipt = async (
  armData: PaymentServiceDataType,
  body: PrintReceipt
): Promise<AxiosResponse<PaymentServiceType>> => {
  const paperStatus = await checkPaperStatus(armData);
  const data = {
    receipt: {
      ...body.receipt,
      orderNumber: `Order: ${body.orderId}`,
      localTimeOffsetMinutes: new Date().getTimezoneOffset()
    },
    printTranslations,
    cleanCash: ''
  };

  const url = getArmDeviceUrl(armData);
  return api.post(`${url}/CompleteReceipt`, data);
};

const checkPaperStatus = async( armData: PaymentServiceDataType) => {
  const url = getArmDeviceUrl(armData);
  try {
    return (await api.post(`${url}/PaperStatus`)).data;
  } 
  catch (error) {
    return 1;
  }
}

const reprintCompleteReceipt = async (
  armData: PaymentServiceDataType,
  body: PrintReceipt
): Promise<AxiosResponse<PaymentServiceType>> => {
  // The data is setup to mimmic Simple POS UI
  const data = {
    receipt: {
      ...body.receipt,
      orderNumber: `Order: ${body.orderId}`,
      printReceipt: false
    },
    printTranslations,
    cleanCash: '',
    isCopy: true
  };

  const url = getArmDeviceUrl(armData);
  return api.post(`${url}/PrintReceiptCopy`, data);
};

const completePayment = (armData: PaymentServiceDataType, body: PrintReceipt) => 
  printCompleteReceipt(armData, body);

export const submitPayment = async (
  armData: PaymentServiceDataType
): Promise<PaymentServiceType> => {
  const armUrl = getArmDeviceUrl({ ...armData });
  const paymentData = composePaymentData({ ...armData });
  const { sessionId } = await makePayment(armUrl, paymentData);
  const getStatus = await requestRepeat(() =>
    getTerminalStatus(armUrl, sessionId)
  );

  return getStatus;
};

export const armPaymentService = (
  data: PaymentServiceDataType
): ArmPaymentServiceType => ({
  submitPayment: () => submitPayment(data),
  completePayment: (body: PrintReceipt) => completePayment(data, body),
  reprintCompleteReceipt: (body: PrintReceipt) =>
    reprintCompleteReceipt(data, body)
});
