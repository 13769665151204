export type ModifierGroup = {
  id: number;
  required: boolean;
  displayName: string;
  displayType: ModifierGroupType;
  modifiers: Modifier[];
  min?: number;
  max?: number;
};

export type Modifier = {
  id: number;
  name: string;
  defaultValue?: number;
  salesPrice?: number;
  removalPriceDeduction?: number;
  min?: number;
  max?: number;
  imageUrl?: string;
  quantity?: number;
};

export enum ModifierGroupType {
  NumberPicker = 'NumberPicker',
  CheckboxButtons = 'CheckboxButtons'
}
